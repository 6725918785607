<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="showDialog"
    btnColor="success"
    clickedText="Create Transaction"
    title="Create Transaction"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-container fluid grid-list-xl>
          <v-layout wrap align-center>
            <v-flex xs12 d-flex>
              <v-select
                :items="transactionTypes"
                :label="$t('_transactionType')"
                v-model="transactionType"
                item-text="name"
                item-value="type"
                persistent-hint
                return-object
              ></v-select>
            </v-flex>
            <v-flex xs12 v-if="isLoadingWallets > 0" class="text-xs-center">
              <v-progress-circular indeterminate color="success" size="20"></v-progress-circular>
            </v-flex>
            <v-flex xs12 d-flex v-else>
              <v-select
                :items="wallets"
                :label="'Wallet'"
                v-model="wallet"
                item-text="name"
                item-value="name"
                persistent-hint
                return-object
              ></v-select>
            </v-flex>

            <v-layout wrap align-center xs12 v-if="transactionType.type === 'manual'" style="padding: 0px 16px">
              <v-flex xs6 d-flex>
                <v-menu
                  ref="txDate"
                  v-model="txDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template slot="activator">
                    <v-text-field
                      v-model="transactionDate"
                      label="Transaction Date"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="transactionDate" no-title scrollable> </v-date-picker>
                </v-menu>
                <v-menu
                  ref="txTime"
                  v-model="txTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="transactionTime"
                      label="Transaction Time (Your Local Time)"
                      prepend-icon="watch_later"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-model="transactionTime" scrollable format="ampm" use-seconds></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 d-flex>
                <v-select
                  :items="manual.transactionTypes"
                  :label="'Manual Transaction Type'"
                  v-model="manual.transactionType"
                  :hint="`${manual.transactionType ? manual.transactionType.name : ''}`"
                  item-text="name"
                  item-value="type"
                  persistent-hint
                  return-object
                ></v-select>
              </v-flex>

              <v-flex xs6 d-flex>
                <v-text-field label="Amount" v-model="manual.amount" type="number"></v-text-field>
              </v-flex>
              <v-flex xs6 d-flex>
                <v-text-field :label="$t('_coinInput')" v-model="manual.coin"></v-text-field>
              </v-flex>

              <v-flex xs6 d-flex>
                <v-text-field label="Address From" v-model="manual.addressFrom"></v-text-field>
              </v-flex>
              <v-flex xs6 d-flex>
                <v-text-field label="Address To" v-model="manual.addressTo"></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout wrap align-center xs12 v-if="transactionType.type === 'trade'" style="padding: 0px 16px">
              <v-flex xs6 d-flex>
                <v-menu
                  ref="txDate"
                  v-model="txDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template slot="activator">
                    <v-text-field
                      v-model="transactionDate"
                      label="Transaction Date"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="transactionDate" no-title scrollable> </v-date-picker>
                </v-menu>
                <v-menu
                  ref="txTime"
                  v-model="txTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="transactionTime"
                      label="Transaction Time (Your Local Time)"
                      prepend-icon="watch_later"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-model="transactionTime" scrollable format="ampm" use-seconds></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="trade.transactionTypes"
                  :label="'Trade Transaction Type'"
                  v-model="trade.transactionType"
                  item-text="name"
                  item-value="type"
                  persistent-hint
                  return-object
                ></v-select>
              </v-flex>
            </v-layout>
            <div style="width: 100%; padding: 0px 16px" v-if="transactionType.type === 'trade'">
              <p style="margin: 0px; padding: 0px">Acquired Assets:</p>
              <v-layout wrap align-center>
                <v-flex xs6 d-flex>
                  <v-text-field label="Amount" v-model="trade.acquiredAmount"></v-text-field>
                </v-flex>
                <v-flex xs6 d-flex>
                  <v-text-field :label="$t('_coinInput')" v-model="trade.acquiredCoin"></v-text-field>
                </v-flex>
              </v-layout>
            </div>
            <div style="width: 100%; padding: 0px 16px" v-if="transactionType.type === 'trade'">
              <p style="margin: 0px; padding: 0px">Disposed Assets:</p>
              <v-layout wrap align-center>
                <v-flex xs6 d-flex>
                  <v-text-field label="Amount" v-model="trade.disposedAmount"></v-text-field>
                </v-flex>
                <v-flex xs6 d-flex>
                  <v-text-field :label="$t('_coinInput')" v-model="trade.disposedCoin"></v-text-field>
                </v-flex>

                <v-flex xs6 d-flex>
                  <v-text-field label="Fees" v-model="trade.feesAmount"></v-text-field>
                </v-flex>
                <v-flex xs6 d-flex>
                  <v-text-field :label="$t('_coinInput')" v-model="trade.feesCoin"></v-text-field>
                </v-flex>
              </v-layout>
            </div>
          </v-layout>
        </v-container>
      </v-layout>
    </v-container>
    <v-divider></v-divider>
    <v-flex d-flex>
      <div style="display: flex; justify-content: flex-end">
        <v-btn large color="primary" @click="handleSaves"> Save </v-btn>
      </div>
    </v-flex>
  </ba-dialog>
</template>

<script>
import { WalletsQuery } from '../../queries/transactionsPageQuery';
export default {
  name: 'CreateManualTransactionModal',
  props: ['dialog', 'handleSave', 'handleChangeDialog'],
  data() {
    return {
      transactionTypes: [
        { type: 'manual', name: 'Manual Transaction' },
        { type: 'trade', name: 'Trade Transaction' },
      ],
      transactionType: '',
      manual: {
        transactionTypes: [
          { type: 'deposit', name: 'Deposit' },
          { type: 'withdrawal', name: 'Withdrawal' },
        ],
        transactionType: '',
        coin: '',
        addressFrom: '',
        addressTo: '',
        amount: '',
      },
      trade: {
        transactionTypes: [
          { type: 'sell', name: 'Sell' },
          { type: 'buy', name: 'Buy' },
        ],
        transactionType: '',
        acquiredAmount: '',
        acquiredCoin: '',
        disposedAmount: '',
        disposedCoin: '',
        feesAmount: '',
        feesCoin: '',
      },
      wallet: '',
      txDate: false,
      transactionDate: '',
      txTime: false,
      transactionTime: '',
      isLoadingWallets: 0,
    };
  },
  methods: {
    showDialog() {
      if (!this.dialog) {
        this.$emit('handleChangeDialog');
      }
    },
    resetForm() {
      const initialState = {
        transactionTypes: [
          { type: 'manual', name: 'Manual Transaction' },
          { type: 'trade', name: 'Trade Transaction' },
        ],
        transactionType: '',
        manual: {
          transactionTypes: [
            { type: 'deposit', name: 'Deposit' },
            { type: 'withdrawal', name: 'Withdrawal' },
          ],
          transactionType: '',
          coin: '',
          addressFrom: '',
          addressTo: '',
          amount: '',
        },
        trade: {
          transactionTypes: [
            { type: 'sell', name: 'Sell' },
            { type: 'buy', name: 'Buy' },
          ],
          transactionType: '',
          acquiredAmount: '',
          acquiredCoin: '',
          disposedAmount: '',
          disposedCoin: '',
          feesAmount: '',
          feesCoin: '',
        },
        wallet: '',
        txDate: false,
        transactionDate: '',
        txTime: false,
        transactionTime: '',
      };
      Object.assign(this.$data, initialState);
    },
    handleSaves() {
      const { transactionDate, transactionTime, transactionType, manual, trade, wallet } = this;

      const resetFormCallback = this.resetForm.bind(this);

      const data = {
        transactionType,
        transactionDate,
        transactionTime,
        manual,
        trade,
        wallet,
      };

      this.$emit('handleSave', data, resetFormCallback);
    },
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingWallets',
    },
  },
};
</script>
