<template>
  <create-manual-transaction-modal
    :dialog="dialog"
    v-on:handleChangeDialog="handleChangeDialog"
    v-on:handleSave="handleCreateTransaction"
  />
</template>
<script>
import { stringifyError } from '@/utils/error';

import { createTransactionMutation } from '../../mutations/transactionMutations';
import { createTxnRequest } from '../../services/transactionServices';
import { MUT_SNACKBAR } from '../../store';
import CreateManualTransactionModal from './CreateManualTransactionModal.vue';

export default {
  name: 'CreateManualTransaction',
  components: {
    CreateManualTransactionModal,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    handleChangeDialog() {
      this.dialog = !this.dialog;
    },
    trigger() {
      this.$el.querySelector('span').click();
    },
    async handleCreateTransaction(data = {}, callback) {
      const txn = createTxnRequest(data);
      if (!txn) {
        return;
      }

      const orgId = this.$store.state.currentOrg.id;
      const sourceId = data.wallet.id;
      const variables = { txn, orgId, sourceId };
      const mutation = createTransactionMutation();
      try {
        await this.$apollo.mutate({
          mutation,
          variables,
        });
      } catch (e) {
        console.log(e);
        const error = stringifyError(e, { hideErrorName: true }).replace('GraphQL error: ', '');
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: error,
        });
      }

      callback();
      this.dialog = !this.dialog;

      this.$emit('refresh');
    },
  },
};
</script>
